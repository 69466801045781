<template>
  <!-- 话题详情 -->
  <div class="TopicDetail public_main_bgc">
    <div class="ArrBox">
      <!-- 导航 -->
      <div class="title">
        <p>
          当前位置：四名工作室 >
          <span>话题研讨</span>
        </p>
      </div>
      <div class="Box">
        <div class="mBox">
          <h4>{{ topicDetail.title }}</h4>
          <div style="border-bottom: 1px dashed #ebebeb; padding: 26px 0 30px">
            <div class="fsbc">
              <div class="common">
                <span>发起人：</span>
                <span class="mr">{{ topicDetail.username }}</span>
                <span>发起时间：</span>
                <span class="mr">{{ topicDetail.create_time }}</span>
                <span class="mr other">{{ topicDetail.reply_count }}评论</span>
                <span class="other">{{ topicDetail.visit_count }}浏览</span>
              </div>
              <p class="reply" @click="scrollele" v-if="isToken">回复话题</p>
            </div>
          </div>
          <div class="content_div rich_text_div_img" v-html="topicDetail.content"></div>
          <div class="new_comment">
            <new-comment :activity_id="top_id" :module_type="3" ref="commentRef"></new-comment>
          </div>
          <!-- 发表评论 -->
          <div class="new_releaseword" v-if="isToken">发表评论</div>
          <div class="new_release" v-if="isToken">
            <div class="seven_right" id="box2">
              <el-input type="textarea" :rows="6" placeholder="请输入回复内容" v-model="CommentList.content" resize="none"></el-input>
              <div class="release cursor" @click="setComment">发表评论</div>
            </div>
          </div>
        </div>

        <!-- 右边排行 -->
        <div class="b_right">
          <!-- 标题 -->
          <div class="title">
            <span>热门话题</span>
          </div>
          <!-- 内容循环部分 -->
          <div class="main_item" v-for="(item, index) in hotData" :key="index">
            <div class="m_content">
              <div class="content_left">
                <!-- <span>{{ item.id }}</span> -->
                <div :class="
                    index + 1 == 1
                      ? 'span1'
                      : index + 1 == 2
                      ? 'span2'
                      : index + 1 == 3
                      ? 'span3'
                      : 'others'
                  ">
                  {{ index + 1 }}
                </div>
              </div>
              <div class="content_right">
                <h5 class="title" @click="toDetails(item.id)" :title="item.title">
                  #{{ item.title }}
                </h5>
                <p>
                  <span>浏览：{{ item.visit_count }}</span>
                  <span>评论：{{ item.reply_count }}</span>
                </p>
              </div>
            </div>
          </div>
          <!-- end循环 -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { SendComment } from "@/api/teacherStudio";
export default {
  data() {
    return {
      isToken: false,
      //热门话题
      hotData: [],
      //话题详情
      topicDetail: "",
      top_id: "",
      //发表评论
      CommentList: {
        gambit_id: "",
        user_id: localStorage.getItem("id"),
        content: "",
      },
      newdata: 0,
    };
  },
  created() {
    this.top_id = this.$route.query.tid;
    this.CommentList.gambit_id = this.top_id;
    //初始化
    this.init();
  },
  computed: {},
  methods: {
    // 初始化
    init() {
      this.isToken = localStorage.getItem("token") == null ? false : true;
      //获取热门话题
      this.getHotTopic();
      //获取话题详情
      this.getTopicDetail();
    },
    //点击滚动到相应元素
    scrollele() {
      document.getElementById("box2").scrollIntoView();
    },
    // 发表评论
    setComment() {
      if (!this.CommentList.content.trim()) {
        return this.$message.warning("发表的评论内容不能为空");
      }
      SendComment(this.CommentList).then((res) => {
        this.$message.success("评论成功");
        this.CommentList.content = "";
        this.$refs.commentRef.getCommentList();
      });
    },

    //获取话题详情
    getTopicDetail() {
      let obj = {
        id: this.top_id,
        teaching_studio_id: localStorage.getItem("studioId"),
      };
      this.$axios.get(`TeachingStudio/gambit/${this.top_id}`).then((res) => {
        this.topicDetail = res.data.data;
        console.log(res, "详情");
      });
    },

    //跳转话题详情
    toDetails(id) {
      this.$router.push({
        path: "/TopicDetail",
        query: {
          tid: id,
          t: Date.now(),
        },
      });
    },

    //获取热门话题
    getHotTopic() {
      this.$axios
        .get("TeachingStudio/gambits/hot", {
          params: {
            teaching_studio_id: localStorage.getItem("studioId"),
          },
        })
        .then((res) => {
          this.hotData = res.data.data.slice(0, 5);
        });
    },
  },
};
</script>
<style lang="less" scoped>
.TopicDetail {
  .ArrBox {
    width: 1400px;
    margin: 0 auto;
    //导航
    .title {
      height: 48px;
      font-size: 14px;
      font-family: Microsoft YaHei Regular, Microsoft YaHei Regular-Regular;
      font-weight: 400;
      text-align: left;
      color: #999999;
      display: flex;
      align-items: center;
      p {
        span {
          color: #ff8201;
        }
      }
    }
    .Box {
      display: flex;
      //详情内容和评论部分
      .mBox {
        width: 1045px;
        // height: 2149px;
        box-sizing: border-box;
        margin: 0;
        padding: 0;
        background: #ffffff;
        border: 1px solid #ebebeb;
        border-radius: 6px;
        padding: 40px 30px 39px 30px;
        h4 {
          font-size: 24px;
          font-weight: 700;
          color: #333333;
          line-height: 44px;
        }
        .common {
          color: #999;
          .mr {
            margin-right: 40px;
          }
          .other {
            color: #333;
          }
        }
        .reply {
          width: 110px;
          height: 34px;
          line-height: 34px;
          background: #3489fe;
          border-radius: 4px;
          font-weight: 600;
          color: #fff;
          text-align: center;
        }
        .content_div {
          line-height: 32px;
          padding-top: 30px;
          padding-bottom: 42px;
        }
        .new_releaseword {
          font-size: 18px;
          font-weight: 700;
          color: #1a1a1a;
          margin-top: 50px;
          margin-bottom: 26px;
        }
        .new_release {
          margin-bottom: 37px;

          .seven_left {
            display: flex;
            flex-direction: column;
            align-items: center;
            .images {
              margin-bottom: 13px;
              img {
                width: 50px;
                height: 50px;
              }
            }
          }
          .seven_right {
            width: 100%;
            height: 184px;
            border: 1px solid #ececec;
            border-radius: 4px;
            margin-right: 30px;
            padding-top: 20px;
            .release {
              width: 92px;
              height: 34px;
              background: #fa8c15;
              border-radius: 4px;
              font-weight: 700;
              color: #ffffff;
              display: flex;
              justify-content: center;
              align-items: center;
              float: right;
              margin-right: 13px;
            }
            /deep/ .el-textarea__inner {
              border: none;
            }
          }
        }
      }
      //热门话题
      .b_right {
        width: 335px;
        height: 654px;

        border: 1px solid #fff;
        background-color: #fff;
        border-radius: 6px;
        padding: 24px 24px 0px 24px;
        margin-left: 20px;
        .title {
          span {
            width: 64px;
            font-size: 16px;
            font-family: Microsoft YaHei Bold, Microsoft YaHei Bold-Bold;
            font-weight: 700;
            text-align: left;
            color: #333333;
          }
        }
        //  循环部分
        .main_item {
          margin-bottom: 19px;
          .m_content {
            display: flex;

            //  内容左边序号
            .content_left {
              margin-right: 27px;
              .span1 {
                width: 24px;
                height: 24px;
                border-radius: 2px;
                text-align: center;
                line-height: 24px;
                font-size: 14px;
                font-family: DIN Black Regular, DIN Black Regular-Regular;
                font-weight: 400;
                color: #fff;
                background: #fa8c15;
              }
              .span2 {
                width: 24px;
                height: 24px;
                border-radius: 2px;
                text-align: center;
                line-height: 24px;
                font-size: 14px;
                font-family: DIN Black Regular, DIN Black Regular-Regular;
                font-weight: 400;
                color: #fff;
                background: #3489ff;
              }
              .span3 {
                width: 24px;
                height: 24px;
                border-radius: 2px;
                text-align: center;
                line-height: 24px;
                font-size: 14px;
                font-family: DIN Black Regular, DIN Black Regular-Regular;
                font-weight: 400;
                color: #fff;
                background: #00c1ae;
              }
              .others {
                width: 24px;
                height: 24px;
                border-radius: 2px;
                text-align: center;
                line-height: 24px;
                font-size: 14px;
                font-family: DIN Black Regular, DIN Black Regular-Regular;
                font-weight: 400;
                color: #fff;
                background: #c0c4cc;
              }
            }
            //  内容右边
            .content_right {
              border-bottom: 1px dashed #ebebeb;
              width: 249px;
              margin-top: -5px;
              padding-bottom: 19px;
              cursor: pointer;
              h5 {
                font-size: 16px;
                font-weight: 600;
                color: #333333;
                line-height: 26px;
              }
              h5:hover {
                color: #3489fe;
              }

              .title {
                width: 249px;
                font-size: 16px;
                font-family: Microsoft YaHei Regular,
                  Microsoft YaHei Regular-Regular;
                font-weight: 400;
                text-align: left;
                color: #333333;
                line-height: 26px;
                white-space: pre-line;
                overflow: hidden;
                -webkit-line-clamp: 2;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-box-orient: vertical;
              }
              p {
                span {
                  font-size: 14px;
                  color: #999999;
                  margin-right: 20px;
                }
              }
            }
          }
        }
        .main_item:last-child {
          margin-bottom: 0px;
          .content_right {
            border-bottom: 0px dashed #ebebeb;
          }
        }
      }
    }
  }
}
</style>
