var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"TopicDetail public_main_bgc"},[_c('div',{staticClass:"ArrBox"},[_vm._m(0),_c('div',{staticClass:"Box"},[_c('div',{staticClass:"mBox"},[_c('h4',[_vm._v(_vm._s(_vm.topicDetail.title))]),_c('div',{staticStyle:{"border-bottom":"1px dashed #ebebeb","padding":"26px 0 30px"}},[_c('div',{staticClass:"fsbc"},[_c('div',{staticClass:"common"},[_c('span',[_vm._v("发起人：")]),_c('span',{staticClass:"mr"},[_vm._v(_vm._s(_vm.topicDetail.username))]),_c('span',[_vm._v("发起时间：")]),_c('span',{staticClass:"mr"},[_vm._v(_vm._s(_vm.topicDetail.create_time))]),_c('span',{staticClass:"mr other"},[_vm._v(_vm._s(_vm.topicDetail.reply_count)+"评论")]),_c('span',{staticClass:"other"},[_vm._v(_vm._s(_vm.topicDetail.visit_count)+"浏览")])]),(_vm.isToken)?_c('p',{staticClass:"reply",on:{"click":_vm.scrollele}},[_vm._v("回复话题")]):_vm._e()])]),_c('div',{staticClass:"content_div rich_text_div_img",domProps:{"innerHTML":_vm._s(_vm.topicDetail.content)}}),_c('div',{staticClass:"new_comment"},[_c('new-comment',{ref:"commentRef",attrs:{"activity_id":_vm.top_id,"module_type":3}})],1),(_vm.isToken)?_c('div',{staticClass:"new_releaseword"},[_vm._v("发表评论")]):_vm._e(),(_vm.isToken)?_c('div',{staticClass:"new_release"},[_c('div',{staticClass:"seven_right",attrs:{"id":"box2"}},[_c('el-input',{attrs:{"type":"textarea","rows":6,"placeholder":"请输入回复内容","resize":"none"},model:{value:(_vm.CommentList.content),callback:function ($$v) {_vm.$set(_vm.CommentList, "content", $$v)},expression:"CommentList.content"}}),_c('div',{staticClass:"release cursor",on:{"click":_vm.setComment}},[_vm._v("发表评论")])],1)]):_vm._e()]),_c('div',{staticClass:"b_right"},[_vm._m(1),_vm._l((_vm.hotData),function(item,index){return _c('div',{key:index,staticClass:"main_item"},[_c('div',{staticClass:"m_content"},[_c('div',{staticClass:"content_left"},[_c('div',{class:index + 1 == 1
                    ? 'span1'
                    : index + 1 == 2
                    ? 'span2'
                    : index + 1 == 3
                    ? 'span3'
                    : 'others'},[_vm._v(" "+_vm._s(index + 1)+" ")])]),_c('div',{staticClass:"content_right"},[_c('h5',{staticClass:"title",attrs:{"title":item.title},on:{"click":function($event){return _vm.toDetails(item.id)}}},[_vm._v(" #"+_vm._s(item.title)+" ")]),_c('p',[_c('span',[_vm._v("浏览："+_vm._s(item.visit_count))]),_c('span',[_vm._v("评论："+_vm._s(item.reply_count))])])])])])})],2)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title"},[_c('p',[_vm._v(" 当前位置：四名工作室 > "),_c('span',[_vm._v("话题研讨")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title"},[_c('span',[_vm._v("热门话题")])])
}]

export { render, staticRenderFns }